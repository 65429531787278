import * as Sentry from '@sentry/vue';
import clone from 'just-clone';
import { defineStore } from 'pinia';
import { RouteRecordNormalized } from 'vue-router';
import { UserDataInterface } from '@/interfaces/users';

const userDataDefault: UserDataInterface = {
  id: 0,
  email: 'john_doe@example.com',
  first_name: 'John',
  last_name: 'Doe',
  is_superuser: false,
  can_manage_other_users: false,
  group: {
    id: 0,
    group_type: 'client',
    company: {
      id: 0,
      name: 'Example',
    },
  },
  is_impersonate: false,
};

// eslint-disable-next-line import/prefer-default-export
export const useStore = defineStore('main', {
  state: () => ({
    latestFromRoute: { matched: [] as RouteRecordNormalized[] },
    loggedIn: false,
    userData: clone(userDataDefault),
  }),
  actions: {
    logIn(userData: UserDataInterface) {
      this.userData = userData;
      this.loggedIn = true;
      Sentry.setUser({ email: userData.email });
    },
    logOut() {
      this.loggedIn = false;
      this.userData = clone(userDataDefault);
      Sentry.configureScope((scope) => scope.setUser(null));
    },
  },
});
