import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteLocationRaw,
  RouteRecordName,
  RouteRecordRaw,
} from 'vue-router';
import { useStore } from '@/store';

type GatekeepingResult = {
  action: 'allow';
} | {
  action: 'redirect_to_login';
} | {
  action: 'redirect';
  to: RouteLocationRaw;
}

type GatekeepingFunction = (to: RouteLocationNormalized) => GatekeepingResult

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/users/Login.vue'),
    meta: { allowAnonymous: true },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/users/Logout.vue'),
  },
  {
    path: '/password-reset',
    name: 'password_reset',
    component: () => import(/* webpackChunkName: "password_reset" */ '../views/users/PasswordReset.vue'),
    meta: { allowAnonymous: true },
  },
  {
    path: '/password-reset/:uidb64/:token',
    name: 'password_reset_confirm',
    component: () => import(/* webpackChunkName: "password_reset_confirm" */ '../views/users/PasswordResetConfirm.vue'),
    meta: { allowAnonymous: true },
    props: true,
  },
  {
    path: '/password-change',
    name: 'password_change',
    component: () => import(/* webpackChunkName: "password_change" */ '../views/users/PasswordChange.vue'),
  },
  {
    path: '/companies',
    name: 'companies',
    component: () => import(/* webpackChunkName: "companies" */ '../views/users/CompanyList.vue'),
    meta: { superuserOnly: true },
  },
  {
    path: '/companies/create',
    name: 'company_create',
    component: () => import(/* webpackChunkName: "company_create" */ '../views/users/CompanyCreateOrEdit.vue'),
    meta: { superuserOnly: true },
  },
  {
    path: '/companies/edit/:companyId(\\d+)',
    name: 'company_edit',
    component: () => import(/* webpackChunkName: "company_edit" */ '../views/users/CompanyCreateOrEdit.vue'),
    meta: { superuserOnly: true },
    props(route) {
      const props = { ...route.params };
      // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
      props.companyId = +props.companyId;
      return props;
    },
  },
  {
    path: '/admins-vendors-clients/:companyId(\\d+)?',
    name: 'admins_vendors_clients',
    component: () => import(/* webpackChunkName: "admins_vendors_clients" */ '../views/users/CompanyDetail.vue'),
    meta: {
      gatekeepingFunction(to: RouteLocationNormalized): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || store.userData.group.group_type !== 'admin'
        ) {
          return { action: 'redirect_to_login' };
        }

        if (store.userData.is_superuser || to.params.companyId === '') {
          return { action: 'allow' };
        }

        return {
          action: 'redirect',
          to: { name: to.name as RouteRecordName, params: { companyId: '' } },
        };
      },
    },
    props(route) {
      const props = { ...route.params };
      if (props.companyId === '') {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.companyId = NaN;
      } else {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.companyId = +props.companyId;
      }
      return props;
    },
  },
  {
    path: '/admin-create/:companyId(\\d+)?',
    name: 'admin_create',
    component: () => import(/* webpackChunkName: "admin_create" */ '../views/users/CreateOrEdit.vue'),
    meta: {
      gatekeepingFunction(to: RouteLocationNormalized): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || store.userData.group.group_type !== 'admin'
          || !store.userData.can_manage_other_users
        ) {
          return { action: 'redirect_to_login' };
        }

        if (store.userData.is_superuser || to.params.companyId === '') {
          return { action: 'allow' };
        }

        return {
          action: 'redirect',
          to: { name: to.name as RouteRecordName, params: { companyId: '' } },
        };
      },
    },
    props(route) {
      const props = { ...route.params };
      if (props.companyId === '') {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.companyId = NaN;
      } else {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.companyId = +props.companyId;
      }
      return props;
    },
  },
  {
    path: '/vendors/create/:companyId(\\d+)?',
    name: 'vendor_create',
    component: () => import(/* webpackChunkName: "vendor_create" */ '../views/users/VendorCreateOrEdit.vue'),
    meta: {
      gatekeepingFunction(to: RouteLocationNormalized): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || store.userData.group.group_type !== 'admin'
        ) {
          return { action: 'redirect_to_login' };
        }

        if (store.userData.is_superuser || to.params.companyId === '') {
          return { action: 'allow' };
        }

        return {
          action: 'redirect',
          to: { name: to.name as RouteRecordName, params: { companyId: '' } },
        };
      },
    },
    props(route) {
      const props = { ...route.params };
      if (props.companyId === '') {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.companyId = NaN;
      } else {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.companyId = +props.companyId;
      }
      return props;
    },
  },
  {
    path: '/vendors/edit/:vendorId(\\d+)',
    name: 'vendor_edit',
    component: () => import(/* webpackChunkName: "vendor_edit" */ '../views/users/VendorCreateOrEdit.vue'),
    meta: {
      gatekeepingFunction(): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || store.userData.group.group_type !== 'admin'
        ) {
          return { action: 'redirect_to_login' };
        }

        return { action: 'allow' };
      },
    },
    props(route) {
      const props = { ...route.params };
      // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
      props.vendorId = +props.vendorId;
      return props;
    },
  },
  {
    path: '/vendors/users/:vendorId(\\d+)?',
    name: 'vendor_users',
    component: () => import(/* webpackChunkName: "vendor_users" */ '../views/users/VendorUserList.vue'),
    meta: {
      gatekeepingFunction(to: RouteLocationNormalized): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || ['third_party_vendor', 'client'].includes(store.userData.group.group_type)
          || (
            store.userData.group.group_type === 'vendor'
            && !store.userData.can_manage_other_users
          )
        ) {
          return { action: 'redirect_to_login' };
        }

        if (
          (store.userData.group.group_type === 'admin' && to.params.vendorId !== '')
          || (store.userData.group.group_type === 'vendor' && to.params.vendorId === '')
        ) {
          return { action: 'allow' };
        }

        if (store.userData.group.group_type === 'admin') {
          return {
            action: 'redirect',
            to: { name: 'admins_vendors_clients' },
          };
        }

        return {
          action: 'redirect',
          to: { name: to.name as RouteRecordName, params: { vendorId: '' } },
        };
      },
    },
    props(route) {
      const props = { ...route.params };
      if (props.vendorId === '') {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.vendorId = NaN;
      } else {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.vendorId = +props.vendorId;
      }
      return props;
    },
  },
  {
    path: '/vendors/users/create/:vendorId(\\d+)?',
    name: 'vendor_user_create',
    component: () => import(/* webpackChunkName: "vendor_user_create" */ '../views/users/CreateOrEdit.vue'),
    meta: {
      gatekeepingFunction(to: RouteLocationNormalized): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || ['third_party_vendor', 'client'].includes(store.userData.group.group_type)
          || (
            store.userData.group.group_type === 'vendor'
            && !store.userData.can_manage_other_users
          )
        ) {
          return { action: 'redirect_to_login' };
        }

        if (
          (store.userData.group.group_type === 'admin' && to.params.vendorId !== '')
          || (store.userData.group.group_type === 'vendor' && to.params.vendorId === '')
        ) {
          return { action: 'allow' };
        }

        if (store.userData.group.group_type === 'admin') {
          return {
            action: 'redirect',
            to: { name: 'admins_vendors_clients' },
          };
        }

        return {
          action: 'redirect',
          to: { name: to.name as RouteRecordName, params: { vendorId: '' } },
        };
      },
    },
    props(route) {
      const props = { ...route.params };
      if (props.vendorId === '') {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.vendorId = NaN;
      } else {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.vendorId = +props.vendorId;
      }
      return props;
    },
  },
  {
    path: '/third-party-vendors',
    name: 'third_party_vendors',
    component: () => import(/* webpackChunkName: "third_party_vendors" */ '../views/users/ThirdPartyVendors.vue'),
    meta: {
      gatekeepingFunction(): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || !['admin', 'vendor'].includes(store.userData.group.group_type)
        ) {
          return { action: 'redirect_to_login' };
        }

        if (store.userData.group.group_type === 'vendor') {
          return { action: 'allow' };
        }

        return {
          action: 'redirect',
          to: { name: 'admins_vendors_clients' },
        };
      },
    },
  },
  {
    path: '/third-party-vendors/create/:companyId(\\d+)?',
    name: 'third_party_vendor_create',
    component: () => import(/* webpackChunkName: "third_party_vendor_create" */ '../views/users/ThirdPartyVendorCreateOrEdit.vue'),
    meta: {
      gatekeepingFunction(to: RouteLocationNormalized): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || !['admin', 'vendor'].includes(store.userData.group.group_type)
        ) {
          return { action: 'redirect_to_login' };
        }

        if (store.userData.is_superuser || to.params.companyId === '') {
          return { action: 'allow' };
        }

        return {
          action: 'redirect',
          to: { name: to.name as RouteRecordName, params: { companyId: '' } },
        };
      },
    },
    props(route) {
      const props = { ...route.params };
      if (props.companyId === '') {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.companyId = NaN;
      } else {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.companyId = +props.companyId;
      }
      return props;
    },
  },
  {
    path: '/third-party-vendors/edit/:thirdPartyVendorId(\\d+)',
    name: 'third_party_vendor_edit',
    component: () => import(/* webpackChunkName: "third_party_vendor_edit" */ '../views/users/ThirdPartyVendorCreateOrEdit.vue'),
    meta: {
      gatekeepingFunction(): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || !['admin', 'vendor'].includes(store.userData.group.group_type)
        ) {
          return { action: 'redirect_to_login' };
        }

        return { action: 'allow' };
      },
    },
    props(route) {
      const props = { ...route.params };
      // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
      props.thirdPartyVendorId = +props.thirdPartyVendorId;
      return props;
    },
  },
  {
    path: '/third-party-vendors/users/:thirdPartyVendorId(\\d+)?',
    name: 'third_party_vendor_users',
    component: () => import(/* webpackChunkName: "third_party_vendor_users" */ '../views/users/ThirdPartyVendorUserList.vue'),
    meta: {
      gatekeepingFunction(to: RouteLocationNormalized): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || store.userData.group.group_type === 'client'
          || (
            store.userData.group.group_type === 'third_party_vendor'
            && !store.userData.can_manage_other_users
          )
        ) {
          return { action: 'redirect_to_login' };
        }

        if (
          (['admin', 'vendor'].includes(store.userData.group.group_type) && to.params.thirdPartyVendorId !== '')
          || (store.userData.group.group_type === 'third_party_vendor' && to.params.thirdPartyVendorId === '')
        ) {
          return { action: 'allow' };
        }

        if (store.userData.group.group_type === 'admin') {
          return {
            action: 'redirect',
            to: { name: 'admins_vendors_clients' },
          };
        }

        if (store.userData.group.group_type === 'vendor') {
          return {
            action: 'redirect',
            to: { name: 'third_party_vendors' },
          };
        }

        return {
          action: 'redirect',
          to: { name: to.name as RouteRecordName, params: { thirdPartyVendorId: '' } },
        };
      },
    },
    props(route) {
      const props = { ...route.params };
      if (props.thirdPartyVendorId === '') {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.thirdPartyVendorId = NaN;
      } else {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.thirdPartyVendorId = +props.thirdPartyVendorId;
      }
      return props;
    },
  },
  {
    path: '/third-party-vendors/users/create/:thirdPartyVendorId(\\d+)?',
    name: 'third_party_vendor_user_create',
    component: () => import(/* webpackChunkName: "third_party_vendor_user_create" */ '../views/users/CreateOrEdit.vue'),
    meta: {
      gatekeepingFunction(to: RouteLocationNormalized): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || store.userData.group.group_type === 'client'
          || (
            store.userData.group.group_type === 'third_party_vendor'
            && !store.userData.can_manage_other_users
          )
        ) {
          return { action: 'redirect_to_login' };
        }

        if (
          (['admin', 'vendor'].includes(store.userData.group.group_type) && to.params.thirdPartyVendorId !== '')
          || (store.userData.group.group_type === 'third_party_vendor' && to.params.thirdPartyVendorId === '')
        ) {
          return { action: 'allow' };
        }

        if (store.userData.group.group_type === 'admin') {
          return {
            action: 'redirect',
            to: { name: 'admins_vendors_clients' },
          };
        }

        if (store.userData.group.group_type === 'vendor') {
          return {
            action: 'redirect',
            to: { name: 'third_party_vendors' },
          };
        }

        return {
          action: 'redirect',
          to: { name: to.name as RouteRecordName, params: { thirdPartyVendorId: '' } },
        };
      },
    },
    props(route) {
      const props = { ...route.params };
      if (props.thirdPartyVendorId === '') {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.thirdPartyVendorId = NaN;
      } else {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.thirdPartyVendorId = +props.thirdPartyVendorId;
      }
      return props;
    },
  },
  {
    path: '/clients/create/:companyId(\\d+)?',
    name: 'client_create',
    component: () => import(/* webpackChunkName: "client_create" */ '../views/users/ClientCreateOrEdit.vue'),
    meta: {
      gatekeepingFunction(to: RouteLocationNormalized): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || store.userData.group.group_type !== 'admin'
        ) {
          return { action: 'redirect_to_login' };
        }

        if (store.userData.is_superuser || to.params.companyId === '') {
          return { action: 'allow' };
        }

        return {
          action: 'redirect',
          to: { name: to.name as RouteRecordName, params: { companyId: '' } },
        };
      },
    },
    props(route) {
      const props = { ...route.params };
      if (props.companyId === '') {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.companyId = NaN;
      } else {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.companyId = +props.companyId;
      }
      return props;
    },
  },
  {
    path: '/clients/edit/:clientId(\\d+)',
    name: 'client_edit',
    component: () => import(/* webpackChunkName: "client_edit" */ '../views/users/ClientCreateOrEdit.vue'),
    meta: {
      gatekeepingFunction(): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || store.userData.group.group_type !== 'admin'
        ) {
          return { action: 'redirect_to_login' };
        }

        return { action: 'allow' };
      },
    },
    props(route) {
      const props = { ...route.params };
      // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
      props.clientId = +props.clientId;
      return props;
    },
  },
  {
    path: '/clients/users/:clientId(\\d+)?',
    name: 'client_users',
    component: () => import(/* webpackChunkName: "client_users" */ '../views/users/ClientUserList.vue'),
    meta: {
      gatekeepingFunction(to: RouteLocationNormalized): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || ['vendor', 'third_party_vendor'].includes(store.userData.group.group_type)
          || (
            store.userData.group.group_type === 'client'
            && !store.userData.can_manage_other_users
          )
        ) {
          return { action: 'redirect_to_login' };
        }

        if (
          (store.userData.group.group_type === 'admin' && to.params.clientId !== '')
          || (store.userData.group.group_type === 'client' && to.params.clientId === '')
        ) {
          return { action: 'allow' };
        }

        if (store.userData.group.group_type === 'admin') {
          return {
            action: 'redirect',
            to: { name: 'admins_vendors_clients' },
          };
        }

        return {
          action: 'redirect',
          to: { name: to.name as RouteRecordName, params: { clientId: '' } },
        };
      },
    },
    props(route) {
      const props = { ...route.params };
      if (props.clientId === '') {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.clientId = NaN;
      } else {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.clientId = +props.clientId;
      }
      return props;
    },
  },
  {
    path: '/clients/users/create/:clientId(\\d+)?',
    name: 'client_user_create',
    component: () => import(/* webpackChunkName: "client_user_create" */ '../views/users/CreateOrEdit.vue'),
    meta: {
      gatekeepingFunction(to: RouteLocationNormalized): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || ['vendor', 'third_party_vendor'].includes(store.userData.group.group_type)
          || (
            store.userData.group.group_type === 'client'
            && !store.userData.can_manage_other_users
          )
        ) {
          return { action: 'redirect_to_login' };
        }

        if (
          (store.userData.group.group_type === 'admin' && to.params.clientId !== '')
          || (store.userData.group.group_type === 'client' && to.params.clientId === '')
        ) {
          return { action: 'allow' };
        }

        if (store.userData.group.group_type === 'admin') {
          return {
            action: 'redirect',
            to: { name: 'admins_vendors_clients' },
          };
        }

        return {
          action: 'redirect',
          to: { name: to.name as RouteRecordName, params: { clientId: '' } },
        };
      },
    },
    props(route) {
      const props = { ...route.params };
      if (props.clientId === '') {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.clientId = NaN;
      } else {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.clientId = +props.clientId;
      }
      return props;
    },
  },
  {
    path: '/users/edit/:userId(\\d+)',
    name: 'user_edit',
    component: () => import(/* webpackChunkName: "user_edit" */ '../views/users/CreateOrEdit.vue'),
    meta: {
      gatekeepingFunction(): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || (
            !['admin', 'vendor'].includes(store.userData.group.group_type)
            && !store.userData.can_manage_other_users
          )
        ) {
          return { action: 'redirect_to_login' };
        }

        return { action: 'allow' };
      },
    },
    props(route) {
      const props = { ...route.params };
      // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
      props.userId = +props.userId;
      return props;
    },
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import(/* webpackChunkName: "projects" */ '../views/projects/Projects.vue'),
  },
  {
    path: '/projects/create',
    name: 'project_create',
    component: () => import(/* webpackChunkName: "project_create" */ '../views/projects/ProjectCreate.vue'),
    meta: {
      gatekeepingFunction(): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || ['vendor', 'third_party_vendor'].includes(store.userData.group.group_type)
        ) {
          return { action: 'redirect_to_login' };
        }

        return { action: 'allow' };
      },
    },
  },
  {
    path: '/projects/statuses/:companyId(\\d+)?',
    name: 'project_statuses',
    component: () => import(/* webpackChunkName: "project_statuses" */ '../views/projects/ProjectStatusList.vue'),
    meta: {
      gatekeepingFunction(to: RouteLocationNormalized): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || store.userData.group.group_type !== 'admin'
        ) {
          return { action: 'redirect_to_login' };
        }

        if (store.userData.is_superuser || to.params.companyId === '') {
          return { action: 'allow' };
        }

        return {
          action: 'redirect',
          to: { name: to.name as RouteRecordName, params: { companyId: '' } },
        };
      },
    },
    props(route) {
      const props = { ...route.params };
      if (props.companyId === '') {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.companyId = NaN;
      } else {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.companyId = +props.companyId;
      }
      return props;
    },
  },
  {
    path: '/projects/statuses/create/:companyId(\\d+)?',
    name: 'project_status_create',
    component: () => import(/* webpackChunkName: "project_status_create" */ '../views/projects/ProjectStatusCreateOrEdit.vue'),
    meta: {
      gatekeepingFunction(to: RouteLocationNormalized): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || store.userData.group.group_type !== 'admin'
        ) {
          return { action: 'redirect_to_login' };
        }

        if (store.userData.is_superuser || to.params.companyId === '') {
          return { action: 'allow' };
        }

        return {
          action: 'redirect',
          to: { name: to.name as RouteRecordName, params: { companyId: '' } },
        };
      },
    },
    props(route) {
      const props = { ...route.params };
      if (props.companyId === '') {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.companyId = NaN;
      } else {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.companyId = +props.companyId;
      }
      return props;
    },
  },
  {
    path: '/projects/edit/:projectStatusId(\\d+)',
    name: 'project_status_edit',
    component: () => import(/* webpackChunkName: "project_status_edit" */ '../views/projects/ProjectStatusCreateOrEdit.vue'),
    meta: {
      gatekeepingFunction(): ReturnType<GatekeepingFunction> {
        const store = useStore();

        if (
          !store.loggedIn
          || store.userData.group.group_type !== 'admin'
        ) {
          return { action: 'redirect_to_login' };
        }

        return { action: 'allow' };
      },
    },
    props(route) {
      const props = { ...route.params };
      // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
      props.projectStatusId = +props.projectStatusId;
      return props;
    },
  },
  {
    path: '/projects/:projectId(\\d+)/:taskId(\\d+)?',
    name: 'project',
    component: () => import(/* webpackChunkName: "project" */ '../views/projects/Project.vue'),
    props(route) {
      const props = { ...route.params };
      // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
      props.projectId = +props.projectId;

      if (props.taskId === '') {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.taskId = NaN;
      } else {
        // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
        props.taskId = +props.taskId;
      }

      return props;
    },
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/PageNotFound.vue'),
    meta: { allowAnonymous: true },
  },
];

const scrollPositions = Object(null);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && to.name && scrollPositions[to.name] !== undefined) {
      document.documentElement.scrollTop = scrollPositions[to.name];
    } else if (!(from.name === 'project' && to.name === 'project')) {
      document.documentElement.scrollTop = 0;
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const store = useStore();

  store.latestFromRoute = from;
  const appNotLaunchedContent = document.querySelector('.app-not-launched-content');

  if (appNotLaunchedContent) {
    // The app is not done launching, so we will not proceed any further until
    // it is done

    await new Promise<void>((resolve) => {
      const observer = new MutationObserver(() => {
        observer.disconnect();
        resolve();
      });

      observer.observe(appNotLaunchedContent, { attributes: true });
    });
  }

  let result: GatekeepingResult;

  if (to.matched[0].meta.gatekeepingFunction) {
    result = (to.matched[0].meta.gatekeepingFunction as GatekeepingFunction)(to);
  } else if (to.matched[0].meta.superuserOnly) {
    // Only superusers are allowed to access this route

    result = {
      action: store.loggedIn && store.userData.is_superuser ? 'allow' : 'redirect_to_login',
    };
  } else if (to.matched[0].meta.allowAnonymous) {
    // Any user (logged in or not) is allowed to access this route

    result = { action: 'allow' };
  } else {
    // Any logged in user is allowed to access this route

    result = {
      action: store.loggedIn ? 'allow' : 'redirect_to_login',
    };
  }

  if (result.action === 'allow') {
    if (from.name) {
      scrollPositions[from.name] = document.documentElement.scrollTop;
    }

    next();
  } else if (result.action === 'redirect') {
    next(result.to);
  } else {
    next({
      name: 'login',
      query: { next: to.fullPath },
    });
  }
});

export default router;
