import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import {
  addRequestHandler,
  addResponseHandler,
  RequestHandlerParams,
  setGlobalRequestHeader,
} from '@virgodev/bazaar/functions/api';
import Cookies from 'js-cookie';
import mitt from 'mitt';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Column from 'primevue/column';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import Sidebar from 'primevue/sidebar';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

if (
  /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
  || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
) {
  document.body.classList.add('is-ios');
}

window.appEventBus = mitt();

if (process.env.NODE_ENV === 'development') {
  addRequestHandler(({ options }: RequestHandlerParams) => {
    // eslint-disable-next-line no-param-reassign
    options.credentials = 'include';
  });
}

addResponseHandler('all', () => {
  setGlobalRequestHeader('X-CSRFToken', Cookies.get('csrftoken'));
});

addResponseHandler(403, () => {
  router.push({
    name: 'login',
    query: { next: router.currentRoute.value.fullPath },
  });
});

const app = createApp(App);

app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService);
app.component('AutoComplete', AutoComplete);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Card', Card);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('DataTable', DataTable);
app.component('Dialog', Dialog);
app.component('Dropdown', Dropdown);
app.component('InputText', InputText);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Sidebar', Sidebar);
app.component('Textarea', Textarea);
app.component('Toast', Toast);
app.directive('tooltip', Tooltip);

app.use(createPinia());

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: 'https://ca62e1c8e8fa440aa94b85962c4c024d@errors.danemco.net/106',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

app.use(router)
  .mount('#app');

declare module '@vue/runtime-core' {
  interface GlobalComponents {
    RouterLink: typeof import('vue-router')['RouterLink'];
    RouterView: typeof import('vue-router')['RouterView'];
  }
}
